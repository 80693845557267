/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.row-header {
  margin: 0px auto;
  padding: 0px auto;
}

.row-content {
  margin: 0px auto;
  padding: 20px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.footer {
  background-color: #3498db;
  margin: 0px auto;
  padding: 20px 0px 20px 0px;

}

.jumbotron {
  padding: 70px 30px 70px 30px;
  margin: 0px auto;
  background: #3498db;
  color: floralwhite;
}

address {
  font-size: 80%;
  margin: 0px;
  color: #0f0f0f;
}

.navbar-dark {
background-color: #3498db;
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.outputanim-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.outputanim-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.outputanim-exit {
  opacity: 1;
  transform: translateX(0%);
}

.outputanim-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.output-field::placeholder {
  color: white;
  opacity: 0.9;
}

.output-field {
  background-color: #16a085 !important;
  color: white;
}

.table-fixed {
  table-layout: fixed;
}

.col-form-label {
  padding: 0px 1rem;
}

.form-control {
  line-height: 1rem;
  padding: 0 1rem;
}

.fadeOut {
  background-color: #3498db;
  position: relative;
  overflow: hidden;
  line-height: 0;
  left: -1000px;
  margin: 0;
  opacity: 0;
  height: 0;
  transition: 1.5s;

}

.fadeIn {
  margin-bottom: 0.5rem;
  position: relative;
  transition: 1.5s;
}

.highlight {
  background-color: #2980b9;
  color: white;
}
/*-
@media screen and (min-width: 701px) {
  .mline:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      border-left: 3px solid #2980b9;
      transform: translate(-50%);
  }
}*/

.mline {
position: relative;
}

.table-align{
  width: 100%;
}


.btn-row>div {
  margin: 0.5rem;
}

.btn-row span {
  margin-left: 0.2rem;
  vertical-align: 0.1rem;
}

.btn-row button {
  margin: 0.5rem;
}

.centered {
  text-align: center;
  vertical-align: middle;
}

.table {
  text-align: right;
}

hr {
  border-top: 3px solid #2980b9;
}

.framed-image {
  border: 1px solid #dcdde1;
  border-radius: 10px;
  box-shadow: 5px 2.5px 2.5px lightgray;
  margin: auto;
  display: block;
  right: 0;
}

.tooltip {
  max-width: 30rem;
}

.recharts-surface {
  overflow: visible !important;
}

td {
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  color: black;
  padding: 20px;
  animation: move 2s linear 5 !important;
}

.card-style {
  border: 1px solid lightgray;
  box-shadow: 10px 5px 5px lightgray;
  border-radius: 10px;
  background-color: #f8f9fa !important;
}

.card-style-padding {
  padding: 2rem;
}

.btn-custom {
  background-color: #487eb0;
  color: white;
}

.h2-custom {
  color: #7c795d;
  font-family: 'Trocchi', serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 38px;
  margin: 0;
}

.subheader-custom {
  color: #7f8c8d;
}

.text-justify {
  text-align: justify;
}

.card-model-papers {
  padding: 2rem;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin-top: 1rem;

}

.card-simulation-style-padding {
  padding: 1rem;
}

.card-simulation-style {
  border: 1px solid lightgray;
  box-shadow: 10px 5px 5px lightgray;
  border-radius: 10px;
  background-color: #f8f9fa !important;
  padding:2rem 2rem 0rem 2rem;
}

.btn-custom-warning{
  color:white;
}

.mline_block{
  display: block;
}


.center_element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}


.td_style{
  font-size: 0.85rem;
}

.td_style > tbody > tr> td{
  padding: 0rem !important;
}

.td_style > thead > tr> td{
  padding: 0rem !important;
}
.td_style > td{
    width: 14.285%;
}

.no_margin_bottom{
    margin-bottom: 0rem;
}

.simulationParameterLabel{
    text-align: right;
}

#advancedOptionsDiv .simulationParameterLabel{
  text-align: left;
}

.center_content{
    text-align: center;
}

body {
  overflow-y: scroll; /* Show scrollbars */
}

.container{
    max-width: 95%;
}

.table_results{
    max-width: 100%;
    display: flex;
    font-size: 1.2rem;
    margin: auto;
}
.table_results td,  .table_results th{
    white-space: nowrap;
    padding: 0.5rem 0.2rem!important;
    font-size: 1.5rem;
}

@media screen and (max-width: 1000px) {
 
  table.table_custom th{
      font-size: 0.8rem !important;
    }

    table.table_custom td{
      font-size: 0.7rem !important;
    
    }
     
  }

  @media screen and (min-width: 1000px) and (max-width: 1201px) {
 
    table.table_custom th{
        font-size: 0.9rem !important;
      }
  
      table.table_custom td{
        font-size: 0.8rem !important;
      
      }
       
    }

table.table_custom th{
  border: 1px solid;
  font-size: 1.1rem;
  white-space: nowrap;
  padding: 5px;

}

table.table_custom td{
  border: 1px solid;
  font-size: 1rem;
  white-space: nowrap;
  padding: 5px;

}



.form-control{
  height: calc(0.5em + 0.75rem + 2px);
}

.row{
  margin-left: 0;
  margin-right: 0;
}

.fa-info-circle{
  position: relative;
  top: -0.5em;
  font-size: 80%;
}